import React, { ReactNode } from 'react';
import Link from 'next/link';

import styles from '@styles/NotFound/template.module.scss';

type Props = {
  image: string;
  imageAlt: string;
  background: string;
  title: string;
  text: string;
  button?: string;
  buttonUrl?: string;
  className?: string;
  children?: ReactNode;
};

const NotFound: React.FC<Props> = ({
  image,
  imageAlt,
  background,
  title,
  text,
  button,
  buttonUrl,
  children,
  className,
}) => (
  <section className={`${styles.container} ${className}`}>
    <div
      className={styles.container__image}
      style={{
        backgroundColor: background,
      }}
    >
      <img src={image} alt={imageAlt} />
    </div>

    <div className={styles.container__content}>
      <h1 className={styles.content__title}>{title}</h1>
      <p className={styles.content__text}>{text}</p>

      {button && (
        <Link href={buttonUrl} className={styles.content__button}>
          {button}
        </Link>
      )}
    </div>

    {children}
  </section>
);

export default NotFound;
