import React from 'react';

import { useIntl } from 'react-intl';

import { Layout } from '@components/Layout';

import NotFoundTemplate from '@components/NotFound/index';

const NotFound404: React.FC = () => {
  const { formatMessage } = useIntl();
  const f = (id: string): string => formatMessage({ id: `not_found_404.${id}` });

  const metaData = {
    title: f('meta.title'),
  };

  const data = {
    image: '/images/not-found-404.svg',
    imageAlt: f('image.alt'),
    background: '#FFC069',
    title: f('title'),
    text: f('text'),
    button: f('button.text'),
    buttonUrl: '/',
  };

  return (
    <Layout meta={metaData}>
      <main>
        <NotFoundTemplate {...data} />
      </main>
    </Layout>
  );
};

export default NotFound404;
